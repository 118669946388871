<template>
  <div class="declare">
    <div>
      <div class="tijiao">
        <div @click="tijiaoquanxian">提交</div>

        <div @click="handle_put">添加子账号</div>
        <!-- <div @click="daochus">导出</div> -->
        <div>
          *高等院校、科研院所、医疗机构等单位可申报创新人物奖4项、创新成果奖6项
          <br />*企业及其他类型单位可申报创新人物奖2项、创新成果奖2项
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="create_time" label="时间"></el-table-column>
          <el-table-column
            prop="award_name"
            label="可申报奖项"
          ></el-table-column>
          <el-table-column label="申请人">
            <template slot-scope="scope">
              <!-- <div v-if="">未录入</div> -->
              <div v-if="com_name == ''">{{ scope.row.user_name }}</div>
              <div v-else>{{ scope.row.user_name }}@{{ com_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="accessory1" label="申请进度">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1">未录入</div>
              <div v-if="scope.row.status == 2">录入中</div>
              <div v-if="scope.row.status == 3">已完成(管理员审核)</div>
              <div v-if="scope.row.status == 4">管理员提交</div>
              <div v-if="scope.row.status == 5">管理驳回</div>
              <div v-if="scope.row.status == 6">后台拒绝(退回)</div>
              <div v-if="scope.row.status == 7">完成</div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="pingshen" label="评选结果">

          </el-table-column> -->
          <el-table-column label="评选结果"> 暂无 </el-table-column>
          <el-table-column label="操作" width="350px">
            <template slot-scope="scope">
              <!-- <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handle_put(scope.row)"
                v-if="!scope.row.branch"
              >添加</el-button>-->
              <el-button type="primary" size="mini" @click="chakan(scope.row)"
                >查看</el-button
              >
              <el-button type="primary" size="mini" @click="chongzhi(scope.row)"
                >删除</el-button
              >
              <el-button type="primary" size="mini" @click="bohui(scope.row)"
                >驳回</el-button
              >
              <el-button type="primary" size="mini" @click="daochu(scope.row)"
                >导出</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          layout="prev, pager, next"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="增加子账号"
      :visible.sync="centerDialogVisiblese"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="com_name">
          <el-input v-model="ruleForm.com_name"></el-input>
          <div class="tishi">
            *个人奖为申报人中文名字，创新成果奖为第一完成人中文名字，单位奖为联系人中文名字
          </div>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="奖项" prop="password">
          <el-select v-model="ruleForm.award_id" placeholder="请选择奖项">
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.award_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="申报类型" prop="password">
          <el-select v-model="ruleForm.type_id" placeholder="请选择申报类型">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.com_type"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="部门" prop="branch">
          <el-input v-model="ruleForm.branch"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="user_name">
          <el-input v-model="ruleForm.user_name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <!-- <el-form-item label="权限" prop="group_id">
          <el-select v-model="ruleForm.group_id" placeholder="请选择权限组">
            <el-option
              :label="item.group_name"
              :value="item.id"
              v-for="(item, index) in group"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即创建</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click=" = false">取 消</el-button> -->
        <!-- <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      title="详情"
      :visible.sync="centerDialogVisible"
      width="78%"
      center
    >
      <div v-if="obj_id == 1"></div>
      <div v-else-if="obj_id == 2">
        <innovation1 :objs="objs"></innovation1>
      </div>
      <div v-else-if="obj_id == 3">
        <!-- <present1 :objs="objs"></present1> -->
        <innovativeFiguresIndexManage :objs="objs"></innovativeFiguresIndexManage>
      </div>
      <div v-else-if="obj_id == 4">
        <Promotion1 :objs="objs"></Promotion1>
      </div>
      <div v-else-if="obj_id == 5">
        <Promotions1 :objs="objs"></Promotions1>
      </div>
      <div v-else-if="obj_id == 6">
        <craftsman1 :objs="objs"></craftsman1>
      </div>
      <div v-else-if="obj_id == 7">
        <!-- <achievement1 v-if="obj_id == 7" :objs="objs"></achievement1> -->
        <achievementIndexManage :objs="objs"></achievementIndexManage>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisibles"
      width="30%"
      center
    >
      <div class="tishis">{{ tishi }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibles = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { URL } from "url";
import innovation1 from "../components/innovation1.vue";
import present1 from "../components/present1.vue";
import innovativeFiguresIndexManage from "./innovativeFigures/indexManage.vue";
import achievementIndexManage from "./achievement/indexManage.vue";

import Promotion1 from "../components/Promotion1.vue";
import Promotions1 from "../components/Promotions1.vue";
import craftsman1 from "../components/craftsman1.vue";
import achievement1 from "../components/achievement1.vue";
export function checkPhoneNumber(rule, value, callback) {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    return callback(new Error("请填写手机号码！"));
  } else if (!reg.test(value)) {
    return callback(new Error("请填写正确的手机号码！"));
  } else {
    callback();
  }
}
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

import {
  create_user,
  user_details_list,
  user_child_details,
  submit_audit,
  split_del,
  turn_down,
  type,
  award,
  group_list,
  file_download,
  user_user_export,
} from "../axios/api";
export default {
  components: {
    innovation1,
    present1,
    Promotion1,
    Promotions1,
    craftsman1,
    achievement1,
    innovativeFiguresIndexManage,
    achievementIndexManage
  },
  watch: {
    centerDialogVisible: function (val) {
      if (!val) {
        this.obj_id = null;
      }
    },
  },
  data() {
    return {
      Visible: false,
      centerDialogVisible: false,
      tableData: [],
      tishi: "",
      centerDialogVisibles: false,
      centerDialogVisiblese: false,
      page: "1",
      total: 0,
      objs: {},
      obj_id: "",
      pageSize: 0,
      com_name: sessionStorage.getItem("com_name") || "",
      rules: {
        com_name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        user_name: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        branch: [{ required: true, message: "请输入部门", trigger: "blur" }],
        mobile: [
          { required: true, validator: checkPhoneNumber, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        group_id: [
          { required: true, message: "请选择权限组", trigger: "change" },
        ],
      },
      group: [],
      options: [],
      options1: [],
      ruleForm: {
        com_name: "",
        password: "",
        branch: "",
        mobile: "",
        email: "",
        group_id: "",
        user_name: "",
        award_id: "",
      },
    };
  },
  created() {
    this.user_details_list();
    // this.type();
    this.award_find();
    this.group_list();
  },

  methods: {
    daochu(e) {
      let p = {
        user_split_id: e.id,
      };
      file_download(p).then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute("download", e.award_name);
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    daochus() {
      // let data = {
      //    token: sessionStorage.getItem("Token"),
      // };
      window.location.href =
        "https://cjhdev.xiaoxixing.cn/common/2022/user_user_export?token=" +
        sessionStorage.getItem("Token") +
        "";

      // let p = {
      //   user_split_id: e.id,
      // };
      // file_download(p).then((res) => {
      //   let blob = new Blob([res.data], { type: "application/zip" });
      //   let url = window.URL.createObjectURL(blob);
      //   const link = document.createElement("a"); // 创建a标签
      //   link.setAttribute("download", e.award_name);
      //   link.href = url;
      //   link.click();
      //   URL.revokeObjectURL(url); // 释放
      // });
    },
    group_list() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      group_list(params).then((res) => {
        console.log(res);
        this.group = res.data.data;
      });
    },
    award_find() {
      award().then((res) => {
        this.options1 = res.data.data;
        award;
      });
    },
    typeApi() {
      type().then((res) => {
        console.log(res);
        this.options = res.data.data;
        console.log(this.options);
      });
    },
    chongzhi(e) {
      this.$confirm("确认重置删除？")
        .then((_) => {
          let params = {
            token: sessionStorage.getItem("Token"),
            id: e.id,
          };
          split_del(params).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "重置成功",
                type: "success",
              });
              this.user_details_list();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch((_) => {});
    },
    bohui(e) {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: e.id,
      };
      turn_down(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "驳回成功",
            type: "success",
          });
          this.user_details_list();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    tijiaoquanxian() {
      this.$confirm("确认提交？")
        .then((_) => {
          let params = {
            token: sessionStorage.getItem("Token"),
          };
          submit_audit(params).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.user_details_list();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch((_) => {});
    },
    chakan(e) {
      console.log(e);
      let params = {
        id: e.id,
        token: sessionStorage.getItem("Token"),
      };
      user_child_details(params).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.obj_id = res.data.data.award_id;
          this.objs = res.data.data;
          this.objs.accomplish_people = this.objs.accomplish_people
            ? this.objs.accomplish_people
            : [];
          this.objs.accomplish = this.objs.accomplish
            ? this.objs.accomplish
            : [];
          this.centerDialogVisible = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    user_details_list() {
      let params = {
        token: sessionStorage.getItem("Token"),
        page: this.page,
      };
      user_details_list(params).then((res) => {
        this.total = parseInt(res.data.data.totalPage);
        this.pageSize = parseInt(res.data.data.pageSize);
        if (res.data.data.status == 2) {
          this.tishi = "已提交(审核中)";
          this.centerDialogVisibles = true;
        } else if (res.data.data.status == 3) {
          this.tishi = "审核通过";
          this.centerDialogVisibles = true;
        } else if (res.data.data.status == 4) {
          this.tishi = "审核驳回,驳回原因：";
          res.data.data.reason.forEach((item) => {
            this.tishi += item.content + ",";
          });

          this.centerDialogVisibles = true;
        }
        this.tableData = res.data.data.data;
        this.tableData.forEach((item) => {
          console.log(item);
          var myDate = new Date(item.create_time * 1000);
          myDate.setDate(myDate.getDate());
          if (item.is_award == 3) {
            item.pingshen = "未评审";
          } else if (item.is_award == 2) {
            item.pingshen = "未获奖";
          } else {
            if (item.award_id == 7) {
              if (item.is_award_details == 1) {
                item.pingshen = "一等奖";
              } else if (item.is_award_details == 2) {
                item.pingshen = "二等奖";
              } else {
                item.pingshen = "优秀奖";
              }
            } else {
              item.pingshen = "已获奖";
            }
          }
          item.create_time = myDate.format("yyyy年MM月dd日");
        });
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.ruleForm.token = sessionStorage.getItem("Token");
          this.ruleForm.group_id = 2;
          create_user(this.ruleForm).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.resetForm("ruleForm");
              this.centerDialogVisiblese = false;
              this.user_details_list();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.centerDialogVisiblese = false;
    },
    handle_put() {
      this.ruleForm = {
        com_name: "",
        password: "",
        branch: "",
        mobile: "",
        email: "",
        group_id: "",
        user_name: "",
        type_id: "",
        award_id: "",
      };
      this.centerDialogVisiblese = true;
    },

    tiaozhuan() {
      // this.$router.push({ path: "/index" });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val + "";
      this.user_details_list();
    },
    handleput(e) {
      console.log(e);
      this.ruleForm.com_name = e.com_name;
      this.ruleForm.user_name = e.user_name;
      this.ruleForm.password = e.password;
      this.ruleForm.branch = e.branch;
      this.ruleForm.mobile = e.mobile;
      this.ruleForm.group_id = e.group_id;
      this.ruleForm.email = e.email;
      this.Visible = true;
    },
    handleDel(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
.declare {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}
.table {
  width: 95%;
  height: 80%;
  margin: 0 auto;
}
.xiangqing {
  width: 100%;
  box-sizing: border-box;
}
.xiangqing > div {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.fenye {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tijiao {
  width: 100%;
  display: flex;

  align-items: center;
}
.tijiao > div {
  padding: 0 20px;
  cursor: pointer;
  margin-right: 20px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #409eff;
  color: #fff;
  border-radius: 10px;
}
.tijiao > div:nth-child(3) {
  background-color: #fff;
  color: rgb(163, 163, 163);
}
.tishis {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.tishi {
  line-height: 25px;
}
</style>
